import React from 'react';
import { useEffect } from 'react';

const Guarantee = () => {
    useEffect(() => {
        document.title = 'Цитрусмолл —  Гарантия';
      }, []);

    return <div className="page">
        <section>
            <h2>Гарантия</h2>
        </section>
    </div>;
};

export default Guarantee;
