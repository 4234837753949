import axios from 'axios';

export async function getCategoryId(category) {
    try {
        const response = await axios.get('https://supersale.pro/api/store/product_categoryes');
        const categories = response.data;
        const categoryData = categories.find(item => item.name === category);
        return categoryData ? categoryData.id : null;
    } catch (error) {
        console.error('Error fetching categories:', error);
        return null;
    }
}

export const fetchCategories = async () => {
    const response = await fetch('https://supersale.pro/api/store/product_categoryes');
    const data = await response.json();
    return data.reduce((acc, category) => {
        acc[category.name] = { id: category.id };
        return acc;
    }, {});
};