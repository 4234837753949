import React from 'react';
import { useEffect } from 'react';

const Delivery = () => {
    useEffect(() => {
        document.title = 'Цитрусмолл — Доставка';
      }, []);

    return <div className="page">
        <section>
            <h1>Доставка</h1>
            <h3>Бесплатная доставка по Беларуси</h3>
            <p>Мы предоставляем нашим клиентам <span class="highlight">удобную</span> услугу <span class="highlight">бесплатной</span> <span class="highlight">доставки</span> по всей <span class="highlight">Беларуси</span>. Независимо от вашего местонахождения, мы обеспечим <span class="highlight">своевременную</span> <span class="highlight">доставку</span> вашего заказа. Наша команда специалистов заботится о каждом этапе <span class="highlight">доставки</span>, чтобы обеспечить вам <span class="highlight">удобство</span> получения товаров в нужное время. Мы осознаем важность соблюдения <span class="highlight">сроков</span> и делаем все возможное, чтобы ваш заказ был <span class="highlight">доставлен</span> в течение <span class="highlight">недели</span>. Сотрудничая с <span class="highlight">надежными</span> партнерами и используя передовые технологии отслеживания, мы гарантируем <span class="highlight">безопасную</span> и <span class="highlight">надежную</span> <span class="highlight">доставку</span> вашего заказа. <span class="highlight">Бесплатная</span> <span class="highlight">доставка</span> распространяется на все виды товаров из нашего ассортимента.</p>
        </section>
        <section>
            <h3>Наша служба доставки</h3>
            <p>Мы <span class="highlight">гордимся</span> качеством нашей <span class="highlight">службы</span> <span class="highlight">доставки</span> и стремимся удовлетворить потребности каждого клиента. Наша цель - сделать процесс покупки и <span class="highlight">доставки</span> максимально <span class="highlight">удобным</span> для вас. Если вам требуется <span class="highlight">доставка</span> в любую точку <span class="highlight">Беларуси</span>, обращайтесь к нам. Мы гарантируем, что ваш заказ будет <span class="highlight">доставлен</span> в течение <span class="highlight">недели</span>, и <span class="highlight">доставка</span> будет <span class="highlight">бесплатной</span>. Ваше время и доверие для нас важны, поэтому мы предпринимаем все возможные меры, чтобы ваша покупка прошла приятно и безопасно.</p>
        </section>
    </div>;
};

export default Delivery;
