import React, { useState } from 'react';
import '../Styles/components/ContactForm.css';
import OrderService from './services/OrderService';

const ContactForm = () => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try{
            await OrderService.createCall(phone, name, message);
            alert('Заявка отправлена!');
        }
        catch{
            alert('Произошла ошибка!');
        }
    };

    return (
        <form className='contact-form' onSubmit={handleSubmit}>
            <div className='contact-div'>
                <label className='contact-label' htmlFor="name">Ваше имя</label>
                <input className='contact-input'
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Иванов Иванович"
                    required
                />
            </div>
            <div className='contact-div'>
                <label className='contact-label' htmlFor="tel">Ваш номер телефона</label>
                <input className='contact-input'
                    type="tel"
                    id="tel"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder="+375 (XX) XXX-XX-XX"
                    required
                />
            </div>
            <div className='contact-div'>
                <label className='contact-label' htmlFor="message">Сообщение</label>
                <textarea
                    className='contact-textarea'
                    id="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Как можем вам помочь?"
                    required
                />
            </div>
            <button className='contact-button' type="submit">Отправить</button>
        </form>
    );
};

export default ContactForm;
