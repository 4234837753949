import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import SearchBar from './SearchBar';
import { isMobile } from 'react-device-detect';
import Logo from '../images/logo.png'
import { fetchCategories } from '../pages/catalog/catalogInfo';

const Header = () => {
    
    const [filtersData, setCategories] = useState([]);

    useEffect(() => {
        const getCategories = async () => {
            const categoriesData = await fetchCategories();
            setCategories(categoriesData);
        };
        getCategories();
    }, []);

    const categories = Object.keys(filtersData).sort();
    const [menuActive, setMenuActive] = useState(false);

    const handleMouseOver = () => {
        setMenuActive(true);
    };

    const handleMouseOut = () => {
        setMenuActive(false);
    };

    return (
        <header className="header">
            <div className="header-left">
                <Link className='logo-icon-container' to="/"><img className='logo-icon' src={Logo} /></Link>
                <Link to="/" className="logo">CITRUSMALL</Link>
                <SearchBar />
            </div>
            <div className="header-right">
                <div className="contact-info">
                    <a className='tel' href="tel:+375293142363">+375 (29) 314-23-63</a>
                </div>
                <nav className="nav">
                    <Link to="/about">О компании</Link>
                    <Link to="/delivery">Доставка</Link>
                    <Link to="/payment">Способы оплаты</Link>
                    <Link to="/installments">Рассрочка</Link>
                    <Link to="/contacts">Контакты</Link>
                </nav>
                <div
                    className={`menu-button-container ${menuActive ? 'active' : ''}`}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                >
                    <Link to="/catalog" className={`menu-button ${menuActive ? 'active' : ''}`}>
                        Каталог<span className='pcText'> товаров</span>
                    </Link>
                    {!isMobile && (
                        <div className="dropdown">
                            {categories.map((category, index) => (
                                <Link key={index} to={`/catalog/${category}`}>
                                    {category}
                                </Link>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </header>
    );
};

export default Header;
