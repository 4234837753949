import React from 'react';
import { useEffect } from 'react';

const Payment = () => {
    useEffect(() => {
        document.title = 'Цитрусмолл — Оплата';
      }, []);

    return <div className="page">
        <section>
            <h1>Оплата</h1>
            <p>Мы обеспечили возможность выбора вами лично желаемой формы оплаты при покупке. Вне зависимости от выбранного варианта оплаты, расчет производится только в белорусских рублях.<br />Вся информация и персональные данные, полученные от вас (включая, но не ограничиваясь: имя, фамилия, телефон, адрес электронной почты и т.д.), надежно хранятся в нашей базе данных в соответствии с действующим законодательством и не передаются третьим лицам.</p>
            <p><b>Наличный расчет</b>
                <br />
                - При получении товара вы можете оплатить покупку наличными курьеру
                <br /><br />
                <b>Безналичный расчет</b>
                <br />
                Оплата банковской картой через терминал
                <br />
                - Оплата курьеру при доставке
                <br /><br />
                <b>Оплата частями</b>
                <br />
                Любой товар можно оплатить частями.<br/>Предоставляется возможность выбора наиболее подходящих для вас условий: суммы первоначального взноса и периода погашения.
            </p>
        </section>
    </div>;
};

export default Payment;
