import React from 'react';

class SearchBar extends React.Component {
  state = {
    searchText: ''
  };

  handleChange = (event) => {
    this.setState({ searchText: event.target.value });
  };

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      console.log('Searching...');
      this.handleSearch(this.state.searchText.toLowerCase());
    }
  };

  handleSearch = (words) => {
    window.location.href = `/catalog?search=${words}`;
  };

  render() {
    return (
      <div className="search-bar">
        <input 
          type="text" 
          placeholder="Поиск по сайту" 
          value={this.state.searchText} 
          onChange={this.handleChange} 
          onKeyDown={this.handleKeyDown} 
        />
      </div>
    );
  }
}

export default SearchBar;
