import React, { useState, useEffect } from 'react';
import '../Styles/components/ShopCard.css';
import { FaStar } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Modal from './Modal';
import Image512 from '../images/512.png';
import InstallmentCalculator from './math/InstallmentCalculator';

const ShopCard = ({ product }) => {
    const [price, setPrice] = useState(1000);
    const [months, setMonths] = useState(12);
    const [installment, setInstallment] = useState(null);
    const [isModalOpen, setModalOpen] = useState(false);
    const [imageSrc, setImageSrc] = useState(product.sourceImage);

    useEffect(() => {
        setPrice(product.price);
        setMonths(48);
    }, []);

    useEffect(() => {
        const calculatedInstallment = InstallmentCalculator.calculateInstallment(price, months);
        setInstallment(calculatedInstallment);
    }, [price, months]);

    const renderStars = () => {
        const rating = product.rating;
        const stars = [];
        for (let i = 0; i < 5; i++) {
            if (i < rating) {
                stars.push(<FaStar key={i} color="#FFA500" />);
            } else {
                stars.push(<FaStar key={i} color="lightgrey" />);
            }
        }
        return stars;
    };

    return (
        <div className="shop-card">
            <Link to={`/product/${product.id}`} className='shop-card__clickable'>
                <img src={imageSrc} onError={() => setImageSrc(Image512)} className="shop-card__image" />
            </Link>
            <div className="shop-card__details">
                <Link to={`/product/${product.id}`} className='shop-card__clickable'>
                    <h2 className="shop-card__name">{product.name}</h2>
                    <p className="shop-card__specs">{product.shortDescription}</p>
                    <div className="shop-card__rating">{renderStars()}</div>
                    <p className="shop-card__price">От {installment} руб. в мес.</p>
                </Link>
                <div className="shop-card__buttons">
                    <Link className="shop-card__btn more-info-btn" to={`/product/${product.id}`}>Подробнее</Link>
                    <button
                        className="shop-card__btn buy-btn "
                        onClick={() => setModalOpen(true)}>Купить</button>
                </div>
            </div>
            <Modal
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
                product={product}
            />
        </div>
    );
};

export default ShopCard;
