import React from 'react';
import '../../Styles/components/GridView.css';
import ShopCard from '../ShopCard';

const GridView = ({ products }) => {
  return (
    <div className="grid-container">
      {products.map((product, index) => (
        <ShopCard key={index} product={product} />
      ))}
    </div>
  );
};

export default GridView;
