import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../Styles/components/ProductPage.css';
import '../Styles/components/ProductCarousel.css';
import Tabs, { Tab } from 'react-best-tabs';
import 'react-best-tabs/dist/index.css';
import InstallmentCalculator from '../components/math/InstallmentCalculator';
import { Link } from 'react-router-dom';
import Modal from '../components/Modal';

const ProductPage = () => {
    const { product } = useParams();
    const [productData, setProductData] = useState(null);
    const [value, setValue] = useState(12);
    const values = [12, 18, 24, 36, 48];
    const [installment, setInstallment] = useState(null);
    const [isModalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        document.title = 'Цитрусмолл';

        const fetchProductData = async () => {
            try {
                const response = await fetch(`https://supersale.pro/api/store/products/${product}`);
                if (!response.ok) {
                    throw new Error('Product not found');
                }
                const data = await response.json();
                setProductData(data);
                document.title = `Цитрусмолл — ${data.name}`;

                const calculatedInstallment = InstallmentCalculator.calculateInstallment(data.price, 12);
                setInstallment(calculatedInstallment);
            } catch (error) {
                setError(true);
                console.error('Error fetching product data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchProductData();
    }, [product]);

    const updateValue = (event) => {
        const sliderValue = parseInt(event.target.value, 10);
        const selectedValue = values[sliderValue];
        setValue(selectedValue);

        if (productData) {
            const calculatedInstallment = InstallmentCalculator.calculateInstallment(productData.price, selectedValue);
            setInstallment(calculatedInstallment);
        }
    };

    if (loading) {
        return <div className='page2'></div>;
    }

    if (error) {
        return (
            <div className='page2' style={{ maxWidth: '400px', textAlign: 'center' }}>
                <h2 style={{ marginBottom: '30px' }}>Товар не найден</h2>
                <Link to="/catalog" className='button-product'>Открыть каталог</Link>
            </div>
        );
    }

    const parseComments = (commentsString) => {
        try {
            return commentsString.split(';').map((comment) => {
                const parts = comment.split(':').map(part => part.trim());
                return { name: parts[0], text: parts[1] };
            }).filter(comment => comment.name && comment.text);
        } catch (error) {
            console.error('Error parsing comments:', error);
            return [];
        }
    };

    const comments = parseComments(productData.comments);

    const ReviewCard = ({ name, text }) => (
        <div className="review-card">
            <h3>{name}</h3>
            <p>{text}</p>
        </div>
    );

    const parseImages = (imagesString) => {
        try {
            return imagesString.split(',').map((image) => image.replace('////', '//'));
        } catch (error) {
            console.error('Error parsing images:', error);
            return [];
        }
    };

    const images = parseImages(productData.additionaImages);

    return (
        <div className="page2">
            <h1 className="title">Купить {productData.name} в рассрочку</h1>
            <div className="product-container">
                <div className="product-carousel">
                    <Carousel showStatus={false}>
                        {images.map((image, index) => (
                            <div key={index}>
                                <img style={{ aspectRatio: '16/9', height: 'auto', width: '100%', objectFit: 'scale-down' }} src={image} alt={`Product Image ${index + 1}`} />
                            </div>
                        ))}
                    </Carousel>
                </div>
                <div className="product-info">
                    <div className="price-info">
                        <span className="price">{installment} р.</span>
                        <p>Среднемесячный платеж</p>
                        <div className="payment-term">
                            <input
                                className="sliderInput"
                                type="range"
                                min="0"
                                max={values.length - 1}
                                step="1"
                                value={values.indexOf(value)}
                                onChange={updateValue}
                            />
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: 2.5, marginRight: 0, marginTop: 8 }}>
                                {values.map((val) => (
                                    <div key={val}>{val}</div>
                                ))}
                            </div>
                        </div>
                        <button onClick={() => setModalOpen(true)} className="submit-btn">Отправить заявку</button>
                    </div>
                </div>
            </div>
            <div className="product-details">
                <Tabs activeTab="1" className="mt-5" ulClassName="" activityClassName="bg-success">
                    <Tab title="Описание" className="mr-3">
                        <div className="mt-3">
                            <p dangerouslySetInnerHTML={{ __html: productData.longDescription }} />
                        </div>
                    </Tab>
                    <Tab title="Отзывы" className="mr-3">
                        <div className="mt-3">
                            {comments.length > 0 ? (
                                <div className="review-list">
                                    {comments.map((comment, index) => (
                                        <ReviewCard key={index} name={comment.name} text={comment.text} />
                                    ))}
                                </div>
                            ) : (
                                <p>Отзывов пока нет</p>
                            )}
                        </div>
                    </Tab>
                </Tabs>
            </div>

            <Modal
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
                product={productData}
                hasInstallment={installment}
                installmentMonths={value}
            />
        </div>
    );
};

export default ProductPage;
