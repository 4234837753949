import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../Styles/components/ErrorPage.css';

const ErrorPage = () => {
    const navigate = useNavigate();

    const goHome = () => {
      navigate('/');
    };

    return (
        <div className='page2'>
            <div className="not-found">
                <h1>404</h1>
                <p>Страница не найдена</p>
                <button onClick={goHome} className="home-button">На главную</button>
            </div>
        </div>
    );
};

export default ErrorPage;
