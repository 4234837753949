import React from 'react';
import { useEffect } from 'react';
import ContactForm from '../components/ContactForm';

const Contacts = () => {
    useEffect(() => {
        document.title = 'Цитрусмолл —  Контакты';
    }, []);

    return <div className="page">
        <section>
            <h2>Контакты</h2>
            <div>
                <h3>Отдел продаж:</h3>
                <p>+375 (29) 314-23-63</p>
                <p>+375 (29) 378-97-19</p>
                <p>+375 (29) 340-90-71</p>
            </div>
            <div>
                <h3>Отдел доставки:</h3>
                <p>+375 (29) 378-93-56</p>
            </div>
            <div>
                <h3>Отдел контроля качества:</h3>
                <p>+375 (29) 341-68-10</p>
            </div>
        </section>
        <selection>
            <ContactForm />
        </selection>
    </div>
        ;
};

export default Contacts;
