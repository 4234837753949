import React, { useState, useEffect } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import { getCategoryId, fetchCategories } from './catalog/catalogInfo';
import '../Styles/components/Catalog.css';
import ShopCard from '../components/ShopCard';

const Catalog = () => {
    const { category } = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const searchQuery = queryParams.get('search');
    const [showFilters, setShowFilters] = useState(false);
    const [productsData, setProductsData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const [filtersData, setCategories] = useState([]);

    useEffect(() => {
        const getCategories = async () => {
            const categoriesData = await fetchCategories();
            setCategories(categoriesData);
        };
        getCategories();
    }, []);

    useEffect(() => {
        document.title = 'Цитрусмолл —  Каталог';
    }, []);

    useEffect(() => {
        setCurrentPage(1);
    }, [category]);

    useEffect(() => {
        fetchProducts();
    }, [category, searchQuery, currentPage]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [currentPage]);

    const fetchProducts = async () => {
        try {
            let url;
            if (searchQuery) {
                url = `https://supersale.pro/api/store/products/paged?pageNumber=${currentPage}&pageSize=18&searchTerm=${encodeURIComponent(searchQuery)}`;
                document.title = `Цитрусмолл — Поиск: ${searchQuery}`;
            } else if (category && category !== 'Все') {
                const categoryId = await getCategoryId(category);
                if (categoryId) {
                    document.title = `Цитрусмолл — ${category}`;
                    url = `https://supersale.pro/api/store/products/paged?pageNumber=${currentPage}&pageSize=18&categoryIds=${categoryId}`;
                } else {
                    console.error('Category ID not found for category:', category);
                    return;
                }
            } else {
                document.title = `Цитрусмолл —  Каталог`;
                url = `https://supersale.pro/api/store/products/paged?pageNumber=${currentPage}&pageSize=18`;
            }
            const response = await fetch(url);
            if (response.ok) {
                const data = await response.json();
                setProductsData(data.data);
                setTotalPages(data.totalPages);
            } else {
                console.error('Error:', response.status);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const renderPagination = () => {
        const pages = [];
        const startPage = Math.max(1, currentPage - 2);
        const endPage = Math.min(totalPages, startPage + 4);

        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <button key={i} onClick={() => setCurrentPage(i)} className={currentPage === i ? 'active' : ''}>
                    {i}
                </button>
            );
        }

        if (startPage > 1) {
            pages.unshift(
                <button key="ellipsisPrev" className="ellipsis" disabled>...</button>
            );
        }

        if (endPage < totalPages) {
            pages.push(
                <button key="ellipsisNext" className="ellipsis" disabled>...</button>
            );
        }

        return pages;
    };

    const toggleFilters = () => {
        setShowFilters((prevState) => !prevState);
    };

    const filteredProducts = Array.isArray(productsData) ? productsData.filter((product) => {
        const categoryId = filtersData[category]?.id;
        const matchesCategory = !category || category === 'Все' || product.categoryId === categoryId;
        const matchesSearchQuery = !searchQuery ||
            Object.keys(product)
                .filter(key => key !== 'image' && key !== 'id')
                .some((key) =>
                    typeof product[key] === 'string' &&
                    product[key].toLowerCase().includes(searchQuery.toLowerCase())
                );

        return matchesCategory && matchesSearchQuery;
    }) : [];

    return (
        <div className='page3'>
            <div className='container'>
                <button className='catalog-button filter-toggle-button' onClick={toggleFilters}>
                    {showFilters ? 'Скрыть категории' : 'Показать категории'}
                </button>
                <aside className={showFilters ? 'filters-visible' : ''}>
                    <h2>Каталог</h2>
                    <div className='filter-section'>
                        <h3>Категории</h3>
                        <ul>
                            <li key='Все'>
                                <Link to='/catalog'>Все</Link>
                            </li>
                            {Object.keys(filtersData).sort().map((cat) => (
                                <li key={cat}>
                                    <Link to={`/catalog/${cat}`}>{cat}</Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </aside>
                <main>
                    {searchQuery ? (
                        <h2 className='catalogTitle'>Товары по запросу "{searchQuery}"</h2>
                    ) : (
                        <h2 className='catalogTitle'>Товары {category && `в категории ${category}`}</h2>
                    )}
                    <div className='product-grid'>
                        {filteredProducts.length > 0 ? (
                            filteredProducts.map((product) => (
                                <ShopCard key={product.id} product={product} />
                            ))
                        ) : (
                            <p className='noProducts'>Нет доступных товаров</p>
                        )}
                    </div>
                    <div className="pagination">
                        {renderPagination()}
                    </div>
                </main>
            </div>
        </div>
    );
};

export default Catalog;
