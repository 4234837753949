import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../Styles/components/ProductCarousel.css';
import GridView from '../components/Grids/GridView';

import banner1 from '../images/banners/вариант 2 ЦМ.png'
import banner2 from '../images/banners/борд 1.png'
import banner3 from '../images/banners/борд 2.png'
import banner4 from '../images/banners/IMG_2001.PNG'
import banner5 from '../images/banners/IMG_2002.PNG'
import banner6 from '../images/banners/IMG_2009.PNG'
import banner7 from '../images/banners/IMG_2010.PNG'


const Main = () => {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        document.title = 'Цитрусмолл — Интернет-магазин товаров в рассрочку';

        const fetchProducts = async () => {
            try {
                const response = await fetch('https://supersale.pro/api/store/products/paged?pageNumber=1&pageSize=3');
                const data = await response.json();
                setProducts(data.data);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchProducts();
    }, []);

    return (
        <div>
            <div className='carouselContainer'>
                <Carousel autoPlay infiniteLoop showThumbs={false} showArrows={true} showStatus={false}>
                    <Link className="aspect-ratio-container" to="/installments">
                        <div className="aspect-ratio-content">
                            <img className='Image' src={banner1}/>
                        </div>
                    </Link>
                    <Link className="aspect-ratio-container" to="/catalog/Электротранспорт">
                        <div className="aspect-ratio-content">
                            <img className='Image' src={banner2}/>
                        </div>
                    </Link>
                    <Link className="aspect-ratio-container" to="/catalog/Смартфоны">
                        <div className="aspect-ratio-content">
                            <img className='Image' src={banner3}/>
                        </div>
                    </Link>
                    <Link className="aspect-ratio-container" to="/catalog/Стиральные%20машины">
                        <div className="aspect-ratio-content">
                            <img className='Image' src={banner4}/>
                        </div>
                    </Link>
                    <Link className="aspect-ratio-container" to="/catalog/Лодки">
                        <div className="aspect-ratio-content">
                            <img className='Image' src={banner7}/>
                        </div>
                    </Link>
                    <Link className="aspect-ratio-container" to="/catalog/Стиральные%20машины">
                        <div className="aspect-ratio-content">
                            <img className='Image' src={banner5}/>
                        </div>
                    </Link>
                    <Link className="aspect-ratio-container" to="/catalog/Телевизоры">
                        <div className="aspect-ratio-content">
                            <img className='Image' src={banner6}/>
                        </div>
                    </Link>
                </Carousel>
            </div>
            <div className="page2">
                <h2 className='popularTitle'>Популярные товары</h2>
                <GridView products={products} />
            </div>
        </div>
    );
};

export default Main;
