class InstallmentCalculator {
  static calculateInstallment(price, months) {
    let x = price * 2 + 100;
    let monthlyRate;

    switch (months) {
      case 12:
        monthlyRate = (x / 12) + ((x / 100 * 9.809) / 12);
        break;
      case 18:
        monthlyRate = (x / 18) + ((x / 100 * 13.991) / 18);
        break;
      case 24:
        monthlyRate = (x / 24) + ((x / 100 * 18.161) / 24);
        break;
      case 36:
        monthlyRate = (x / 36) + ((x / 100 * 26.488) / 36);
        break;
      case 48:
        monthlyRate = (x / 48) + ((x / 100 * 41.830) / 48);
        break;
      default:
        throw new Error('Unsupported number of months');
    }

    return monthlyRate.toFixed(2);
  }
}

export default InstallmentCalculator;
