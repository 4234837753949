import React from 'react';
import { useEffect } from 'react';

const Installments = () => {
    useEffect(() => {
        document.title = 'Цитрусмолл —  Рассрочка';
      }, []);

    return <div className="page">
        <section>
            <h2>Рассрочка</h2>
            <p><b>Рассрочка</b> — это удобная и выгодная возможность приобрести необходимые вам товары, не переплачивая сразу всю сумму.
                <br />Мы предлагаем нашим клиентам уникальную услугу рассрочки на срок от 24 до 48 месяцев. Это значит, что вы можете разделить стоимость покупки на более длительный период и выплачивать ее небольшими ежемесячными платежами. Такой подход позволяет существенно снизить нагрузку на бюджет и легко планировать свои финансы.
                <br />Чтобы воспользоваться рассрочкой, вам необходимо всего лишь выбрать нужный товар, оформить заказ и указать желаемый срок рассрочки.
                <br />Благодаря рассрочке, вы сможете сразу приобрести дорогие товары, не откладывая их покупку на неопределенное время. Вы сможете наслаждаться новыми вещами прямо сейчас, а выплачивать за них постепенно.
                <br />Мы гарантируем прозрачные условия рассрочки, отсутствие скрытых комиссий и переплат. Мы ценим наших клиентов и стремимся сделать покупки максимально комфортными и доступными для каждого.
                <br />Не откладывайте свои желания на потом, воспользуйтесь рассрочкой и сделайте покупку, о которой мечтали уже сегодня!</p>
        </section>
    </div>;
};

export default Installments;
