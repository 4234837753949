import React, { useEffect } from 'react';

const PrivacyPolicy = () => {
    useEffect(() => {
        document.title = 'Цитрусмолл — Политика конфиденциальности';
    }, []);

    return (
        <div className="page">
            <section>
                <h2>Политика конфиденциальности</h2>
                <h3>Защита личных данных</h3>
                <p>
                    Для защиты ваших личных данных у нас внедрен ряд средств защиты, которые действуют при введении, передаче или работе с вашими личными данными.
                </p>
                <h3>Разглашение личных сведений и передача этих сведений третьим лицам</h3>
                <p>
                    Ваши личные сведения могут быть разглашены нами только в том случае, если это необходимо для:
                </p>
                <ul>
                    <li>обеспечения соответствия предписаниям закона или требованиям судебного процесса в нашем отношении;</li>
                    <li>защиты наших прав или собственности;</li>
                    <li>принятия срочных мер по обеспечению личной безопасности наших сотрудников или потребителей предоставляемых им услуг, а также обеспечению общественной безопасности.</li>
                </ul>
                <p>
                    Личные сведения, полученные в наше распоряжение при регистрации, могут передаваться третьим организациям и лицам, состоящим с нами в партнерских отношениях для улучшения качества оказываемых услуг. Эти сведения не будут использоваться в каких-либо иных целях, кроме перечисленных выше. Адрес электронной почты, предоставленный вами при регистрации, может использоваться для отправки вам сообщений или уведомлений об изменениях, связанных с вашей заявкой, а также рассылки сообщений о происходящих в компании событиях и изменениях, важной информации о новых товарах и услугах и т.д. Предусмотрена возможность отказа от подписки на эти почтовые сообщения.
                </p>
                <h3>Использование файлов «cookie»</h3>
                <p>
                    Когда пользователь посещает веб-узел, на его компьютер записывается файл «cookie» (если пользователь разрешает прием таких файлов). Если же пользователь уже посещал данный веб-узел, файл «cookie» считывается с компьютера. Одно из направлений использования файлов «cookie» связано с тем, что с их помощью облегчается сбор статистики посещения. Эти сведения помогают определять, какая информация, отправляемая заказчикам, может представлять для них наибольший интерес. Сбор этих данных осуществляется в обобщенном виде и никогда не соотносится с личными сведениями пользователей.
                </p>
                <p>
                    Третьи стороны, включая компании Google, показывают объявления нашей компании на страницах сайтов в Интернете. Третьи стороны, включая компанию Google, используют cookie, чтобы показывать объявления, основанные на предыдущих посещениях пользователем наших веб-сайтов и интересах в веб-браузерах. Пользователи могут запретить компаниям Google использовать cookie. Для этого необходимо посетить специальную страницу компании Google по этому адресу: <a href="https://www.google.com/privacy/ads/" target="_blank" rel="noopener noreferrer">www.google.com/privacy/ads/</a>
                </p>
                <h3>Изменения в заявлении о соблюдении конфиденциальности</h3>
                <p>
                    Заявление о соблюдении конфиденциальности предполагается периодически обновлять. При этом будет изменяться дата предыдущего обновления, указанная в начале документа. Сообщения об изменениях в данном заявлении будут размещаться на видном месте наших веб-узлов.
                </p>
                <p>
                    Осуществив заказ на нашем сайте какого-либо товара, Вы соглашаетесь получить смс-уведомление о доставке купленного Вами товара в соответствующее почтовое отделение, согласно указанному вами индексу.
                </p>
                <p>
                    Благодарим Вас за проявленный интерес к нашей системе!
                </p>
            </section>
        </div>
    );
};

export default PrivacyPolicy;
